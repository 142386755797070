<template>
    <div class="productDetailBox">
        <div class="box1">
            <div class="box2">
                <div class="topbox ">
                    <el-button size="small" class="elbox " icon="el-icon-arrow-left"
                        @click="$router.push('/productCenter')">返回</el-button>
                    <span class="span1 ">{{ title }}</span>
                </div>
                <div class="box3 "></div>
                <div class="box4 ">
                    <div class="imgbox1" v-for="(item, index) in dataList" :key="index">
                        <img class="" :src="item">
                    </div>
                </div>
                <div class="box5 ">
                    <div class="aaabox btnbox" @click="goContactUs">
                        立即获取解决方案<i class="el-icon-right"></i>
                    </div>
                </div>
                <div class="box6"></div>
                <div class="mrt24 dflex jspacebetween">
                    <div class="c102 f14 lh21 upbtn curp" @click="godetail(1)">
                        <i class="b1sc237A el-icon-arrow-left mrr10"></i>
                        上一篇<span class="span2">：{{ upTitle }}</span>
                    </div>
                    <div class="c102 f14 lh21 upbtn curp" @click="godetail(2)">
                        下一篇<span class="span2">：{{ downTitle }}</span>
                        <i class="b1sc237A el-icon-arrow-right mrl10"></i>
                    </div>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>

<script>
import footers from "../components/footers.vue";
export default {
    components: {
        footers
    },
    data() {
        return {
            dataList: [],
            cpglImgList: [
                require('../assets/img/cpgl1.jpg'),
                require('../assets/img/cpgl2.jpg'),
                require('../assets/img/cpgl3.jpg'),
                require('../assets/img/cpgl4.jpg'),
                require('../assets/img/cpgl5.jpg'),
            ],
            yingxiaoImgList: [
                require('../assets/img/yingxiao1.png'),
            ],
            louyuImgList: [
                require('../assets/img/louyu1.png'),
                require('../assets/img/louyu2.jpg'),
                require('../assets/img/louyu3.jpg'),
                require('../assets/img/louyu4.jpg'),
                require('../assets/img/louyu5.jpg'),
                require('../assets/img/louyu6.jpg'),
                require('../assets/img/louyu7.jpg'),
                require('../assets/img/louyu8.jpg'),
                require('../assets/img/louyu9.jpg'),
                require('../assets/img/louyu10.jpg'),
            ],
            qiyefuwuImgList: [
                require('../assets/img/qiyefuwu1.png'),
                require('../assets/img/qiyefuwu2.png'),
                require('../assets/img/qiyefuwu3.png'),
                require('../assets/img/qiyefuwu4.png'),
                require('../assets/img/qiyefuwu5.png'),
                require('../assets/img/qiyefuwu6.png'),
            ],
            wulianwangImgList: [
                require('../assets/img/wulianwang1.jpg'),
                require('../assets/img/wulianwang2.jpg'),
                require('../assets/img/wulianwang3.jpg'),
                require('../assets/img/wulianwang4.jpg'),
                require('../assets/img/wulianwang5.jpg'),
                require('../assets/img/wulianwang6.jpg'),
                require('../assets/img/wulianwang7.jpg'),
                require('../assets/img/wulianwang8.jpg'),
                require('../assets/img/wulianwang9.jpg'),
                require('../assets/img/wulianwang10.jpg'),
                require('../assets/img/wulianwang11.jpg'),
                require('../assets/img/wulianwang12.jpg'),
                require('../assets/img/wulianwang13.jpg'),
                require('../assets/img/wulianwang14.jpg'),
                require('../assets/img/wulianwang15.jpg'),
                require('../assets/img/wulianwang16.jpg'),
                require('../assets/img/wulianwang17.jpg'),
                require('../assets/img/wulianwang18.jpg'),
                require('../assets/img/wulianwang19.jpg'),
                require('../assets/img/wulianwang20.jpg'),
                require('../assets/img/wulianwang21.jpg'),
                require('../assets/img/wulianwang22.jpg'),
                require('../assets/img/wulianwang23.jpg'),
                require('../assets/img/wulianwang24.jpg'),
            ],
            titleList: [
                '禧闻云-智联通效智能营销系统',
                '数字化闭环式智慧楼宇系统',
                '产业管理服务创新平台',
                '禧闻-智能化物联网产品',
                '禧闻-企业服务智慧平台',
            ],
            title: '',
            upTitle: '',
            downTitle: '',
            titleindex: 0
        }
    },
    watch: {
        'titleindex': {
            immediate: true,
            handler: function (newvalue, oldvalue) {
                console.log('--->newvalue', newvalue)
                if (newvalue == 0) {
                    this.upTitle = '暂无'
                    this.downTitle = this.titleList[1]
                } else if (newvalue == 4) {
                    this.upTitle = this.titleList[3]
                    this.downTitle = '暂无'
                } else {
                    if (newvalue != undefined) {
                        this.upTitle = this.titleList[newvalue - 1]
                        this.downTitle = this.titleList[newvalue + 1]
                        this.changImgList(newvalue + 1)
                    }
                }

            }
        }
    },
    mounted() {
        this.titleindex = this.$route.query.keyword * 1 - 1
        this.title = this.titleList[this.$route.query.keyword * 1 - 1]
        // console.log('--->index',this.titleindex)
        this.changImgList(this.$route.query.keyword)
    },
    methods: {
        changImgList(val) {
            console.log('---->val', val)
            if (val == 1) {
                this.dataList = this.yingxiaoImgList
            } else if (val == 2) {
                this.dataList = this.louyuImgList
            } else if (val == 3) {
                this.dataList = this.cpglImgList
            } else if (val == 4) {
                this.dataList = this.wulianwangImgList
            } else if (val == 5) {
                this.dataList = this.qiyefuwuImgList
            }
            console.log('--->datalist', this.dataList)
        },
        godetail(val) {
            // console.log('--->',this.titleindex)
            if (val == 1) {
                if (this.titleindex == 0) {
                    return
                }
                this.titleindex = this.titleindex * 1 - 1
                this.$router.push(`/productDetail?keyword=${this.titleindex}`)
            }
            if (val == 2) {
                if (this.titleindex == 4) {
                    return
                }
                this.titleindex = this.titleindex * 1 + 1
                this.$router.push(`/productDetail?keyword=${this.titleindex}`)
            }
        },
        goContactUs() {
            this.$router.push('/contactUs')
        }
    }
}
</script>

<style lang="less" scoped>
.box1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 65px;

}

.box2 {
    width: 1200px;

    .topbox {
        width: 100%;
        margin-top: 58px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        position: relative;

        .elbox {
            // psab left10 top8
            position: absolute;
            left: 10px;
            top: 8px;
            display: block;
        }

        .span1 {
            // c51 f30 lh45 fwb
            color: rgba(51, 51, 51, 1);
            font-size: 30px;
            line-height: 45px;
            font-weight: bold;
        }
    }

    .box3 {
        // bgc235 w1200 h1 mrt22
        background-color: rgba(235, 235, 235, 1);
        width: 1200px;
        height: 1px;
        margin-top: 22px;
    }

    .box4 {
        // w100 dfall mrt20 fcolumn
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 20px;

        .imgbox1 {
            // w880 ofcontain
            width: 880px;
            object-fit: contain;

            img {
                width: 100%;
            }
        }
    }

    .box5 {
        // w100 dflex jcenter mrt40
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 40px;

        .aaabox {
            // w200 h40 b1sc1 br5 dfall c1 curp
            width: 200px;
            height: 40px;
            border: 1px solid rgba(1, 83, 158, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(1, 83, 158, 1);
            cursor: pointer;
        }
    }

    .box6 {
        // bgc235 w1200 h1 mrt70
        background-color: rgba(235, 235, 235, 1);
        width: 1200px;
        height: 1px;
        margin-top: 70px;
    }
    .span2{
        display: inline;
    }
}

.btnbox:hover {
    color: #fff !important;
    background: rgba(1, 83, 158, 1);
}

.upbtn:hover {
    color: rgba(1, 83, 158, 1);
}


@media (max-width: 750px) {
    .box1{
        margin-bottom: 20px;
    }
    .box2 {
        width: 100%;

        .topbox {
            width: 100%;
            margin-top: 20px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            position: relative;

            .elbox {
                // psab left10 top8
                display: none;
            }

            .span1 {
                // c51 f30 lh45 fwb
                color: rgba(51, 51, 51, 1);
                font-size: 17px !important;
                line-height: 26px;
                font-weight: bold;
            }
        }

        .box3 {
            // bgc235 w1200 h1 mrt22
            background-color: rgba(235, 235, 235, 1);
            width: 100%;
            height: 1px;
            margin-top: 13px;
        }

        .box4 {
            // w100 dfall mrt20 fcolumn
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 20px;

            .imgbox1 {
                // w880 ofcontain
                width: 240px;
                object-fit: contain;

                img {
                    width: 100%;
                }
            }
        }
        .box5{
            display: none;
        }
        .box6 {
            background-color: rgba(235, 235, 235, 1);
            width: 100%;
            height: 1px;
            margin-top: 20px;
        }
        .span2{
            display: none;
        }
    }

}
</style>

<style lang="less" scoped>
.productDetailBox {
    .el-divider__text {
        font-size: 26px;
        color: #000;
        font-weight: 700;
    }


}
</style>